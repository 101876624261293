const FETCH_ESTACIONES_LIST_SKI = 'FETCH_ESTACIONES_LIST_SKI';
const FETCH_ESTACIONES_ERROR_SKI = 'FETCH_ESTACIONES_ERROR_SKI';
const FETCH_ESTACIONES_SUCCESS_SKI = 'FETCH_ESTACIONES_SUCCESS_SKI';
const ESTACIONES_BUTTON_CLICK_SKI = 'ESTACIONES_BUTTON_CLICK_SKI';
const ESTACION_CLICK_SKI = 'ESTACION_CLICK_SKI';
const HIDE_ERRORS_SKI = 'HIDE_ERRORS_SKI';
const SHOW_ERRORS_SKI = 'SHOW_ERRORS_SKI';
const SECTOR_CLICK_SKI = 'SECTOR_CLICK_SKI';
const START_DATE_SELECTION_SKI = 'START_DATE_SELECTION_SKI';
const END_DATE_SELECTION_SKI = 'END_DATE_SELECTION_SKI';
const SKY_SUBMIT_CLICK_SKI = 'SKY_SUBMIT_CLICK_SKI';
const FORFAIT_BUTTON_CLICK_SKI = 'FORFAIT_BUTTON_CLICK_SKI';
const CHANGE_TO_NO_MATERIAL_SECTOR_OVERLAY = 'CHANGE_TO_NO_MATERIAL_SECTOR_OVERLAY';
const CLOSE_TO_NO_MATERIAL_OVERLAY = 'CLOSE_TO_NO_MATERIAL_OVERLAY';

export { 
  FETCH_ESTACIONES_LIST_SKI, 
  FETCH_ESTACIONES_ERROR_SKI, 
  FETCH_ESTACIONES_SUCCESS_SKI, 
  ESTACIONES_BUTTON_CLICK_SKI,
  ESTACION_CLICK_SKI,
  HIDE_ERRORS_SKI,
  SHOW_ERRORS_SKI,
  SECTOR_CLICK_SKI,
  START_DATE_SELECTION_SKI,
  END_DATE_SELECTION_SKI,
  SKY_SUBMIT_CLICK_SKI,
  FORFAIT_BUTTON_CLICK_SKI,
  CHANGE_TO_NO_MATERIAL_SECTOR_OVERLAY,
  CLOSE_TO_NO_MATERIAL_OVERLAY
};
